import request from '@/utils/request.js'

// 用户登录
export function customerLogin(data) {
  return request({
    url: '/front/customer/login',
    method: 'get',
    params:data
  })
}

// 注册用户信息
export function customerRegister(data) {
  return request({
    url: '/front/customer/register',
    method: 'POST',
    data
  })
}
