"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _withScopeId = n => ((0, vue_2.pushScopeId)("data-v-1301a318"), n = n(), (0, vue_2.popScopeId)(), n);
const _hoisted_1 = { key: 0 };
const _hoisted_2 = { class: "tj" };
const _hoisted_3 = { key: 1 };
const _hoisted_4 = { class: "tj" };
const vue_3 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'unfold',
    props: {
        data: {},
        maxLen: {}
    },
    setup(__props) {
        const { data, maxLen } = __props;
        const showBtn = (0, vue_3.ref)(true);
        const sliceStr = (0, vue_3.computed)(() => {
            return data.substring(0, maxLen) + "...";
        });
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("span", null, [
                (_ctx.data.length < _ctx.maxLen)
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("span", _hoisted_1, [
                        (0, vue_2.createElementVNode)("span", _hoisted_2, (0, vue_2.toDisplayString)(_ctx.data), 1)
                    ]))
                    : ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("span", _hoisted_3, [
                        (0, vue_2.createElementVNode)("span", _hoisted_4, [
                            (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(showBtn.value ? sliceStr.value : _ctx.data) + " ", 1),
                            (0, vue_2.createElementVNode)("span", {
                                class: "btnWord",
                                onClick: _cache[0] || (_cache[0] = ($event) => (showBtn.value = !showBtn.value))
                            }, (0, vue_2.toDisplayString)(showBtn.value ? "展开" : "收起"), 1)
                        ])
                    ]))
            ]));
        };
    }
});
