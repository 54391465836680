<template>
    <div class="bottomSide">
        联系方式：左老师 15092220557；工作时间：周一至周五 9：00-17：00；Copyright © 2017 - 2024 青岛左格会展服务有限公司鲁ICP备2023020776号-1 All Rights Reserved.
    </div>
</template>

<style lang="scss" scoped>
.bottomSide {
  font-family: PingFangSC-Regular;
  font-weight: 400;
  font-size: 0.5rem;
  color: #757575;
  text-align: center;
  padding: 12px 0
}
</style>
