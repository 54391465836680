export function GetDateStr(AddDayCount) {
    var dd = new Date();
    dd.setDate(dd.getDate() + AddDayCount);//获取AddDayCount天后的日期
    var y = dd.getFullYear();
    var m = dd.getMonth() + 1;//获取当前月份的日期
    var mm=m<10?'0'+m:m
    var d = dd.getDate();
    var dd1=d<10?'0'+d:d
    return y + "-" + mm + "-" + dd1;
}

//分隔数组 
export function isArray(arg) {
    for(let i in arg){
        if (Array.isArray(arg[i])) {
            arg[i]= arg[i]+''
          }else{
            arg[i]= arg[i]
          }
    }
    return arg
  }
  