<template>
    <div class="empty">

    </div>
    <div class="emptyText">
        暂无数据
    </div>
</template>

<style lang="scss" scoped>
.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22.27rem;
  height: 12.81rem;
  background-image: url("@/assets/layoutTopImg/empty.png");
  background-size: cover;
  margin: 18.8rem auto 2.23rem auto;
}
.emptyText {
  font-family: PingFangSC-Regular;
  font-weight: 400;
  font-size: 1rem;
  color: #757575;
  text-align: center
}
</style>
