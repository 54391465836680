<template>
  <a-config-provider :locale="locale">
      <a-style-provider hash-priority="high">
      <router-view v-slot="{ Component }">
      <keep-alive :include="include">
        <component :is="Component" :key="$route.name" v-if="$route.meta.keepAlive" />
      </keep-alive>
      <component :is="Component" :key="$route.name" v-if="!$route.meta.keepAlive" />
    </router-view>
    </a-style-provider>
  </a-config-provider>
</template>

<script>
  import zhCN from 'ant-design-vue/es/locale/zh_CN';
  import dayjs from 'dayjs';
  import Cookies from 'js-cookie'
  import 'dayjs/locale/zh-cn';
  dayjs.locale('zh-cn');

  export default {
    data() {
      return {
        include:[],
        locale: zhCN,
      };
    },
      watch: {
        $route(to, from) {
          console.log('to,from',to,from)
        if((from.path=="/basisIndex/createOrder"&&to.path=="/basisIndex/hotelsList")||from.path=="/"){
           console.log('1231',to,from)
           this.include=[]
           Cookies.remove('keepAliveList')
        }
      //如果 要 to(进入) 的页面是需要 keepAlive 缓存的，把 name push 进 include数组
      if (to.meta.keepAlive) {
          !this.include.includes('basisModule') &&this.include.push('basisModule')
        !this.include.includes(to.name) && this.include.push(to.name);
          console.log('this.include',this.include)
         Cookies.set('keepAliveList',  this.include.toString())
      }
      // //如果 要 form(离开) 的页面是 keepAlive缓存的，
      // //再根据 deepth 来判断是前进还是后退
      // //如果是后退
      if (from.meta.keepAlive && to.meta.deepth < from.meta.deepth) {
        var index = this.include.indexOf(from.name);
        console.log('index',index)
        index !== -1 && this.include.splice(index, 1);
         console.log('this.include',this.include)
        Cookies.set('keepAliveList',  this.include.toString())
      }
     }
  }
  };
</script>


