"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _withScopeId = n => ((0, vue_2.pushScopeId)("data-v-6d2ef158"), n = n(), (0, vue_2.popScopeId)(), n);
const _hoisted_1 = { class: "card" };
const _hoisted_2 = { class: "cardLeft" };
const _hoisted_3 = ["src"];
const _hoisted_4 = { style: { "width": "35.56rem" } };
const _hoisted_5 = { class: "cardBreakfastFlag" };
const _hoisted_6 = { key: 0 };
const _hoisted_7 = { key: 1 };
const _hoisted_8 = { key: 2 };
const _hoisted_9 = { key: 3 };
const _hoisted_10 = ["innerHTML"];
const _hoisted_11 = { class: "cardMoney" };
const _hoisted_12 = {
    key: 0,
    class: "cardRoomNumber"
};
const _hoisted_13 = {
    key: 1,
    class: "cardRoomNumber"
};
const _hoisted_14 = {
    key: 2,
    class: "cardRoomNumber",
    style: { "background": "#f8f8f8", "color": "#8c8c8c" }
};
const vue_router_1 = require("vue-router");
const ant_design_vue_1 = require("ant-design-vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'room',
    props: {
        roomTypeItem: { default: () => ({}) }
    },
    setup(__props) {
        const props = __props;
        const route = (0, vue_router_1.useRoute)();
        const router = (0, vue_router_1.useRouter)();
        //去预定房间
        const goOrderReserve = () => {
            let { roomTypeItem } = props;
            if (!roomTypeItem.startTime || !roomTypeItem.endTime) {
                ant_design_vue_1.message.error("请选择期望入住时间");
            }
            else if (roomTypeItem.startTime == roomTypeItem.endTime) {
                ant_design_vue_1.message.error("入住时间和退房时间不能为一天");
            }
            else {
                router.push({
                    path: "/basisIndex/createOrder",
                    query: {
                        roomType: roomTypeItem.id,
                        roomTypeName: roomTypeItem.roomTypeName,
                        hotelId: roomTypeItem.hotelId,
                        price: roomTypeItem.price,
                        remainRoomNum: roomTypeItem.remainRoomNum,
                        earliestReserveTime: roomTypeItem.earliestReserveTime,
                        latestReserveTime: roomTypeItem.latestReserveTime,
                        startTime: roomTypeItem.startTime,
                        endTime: roomTypeItem.endTime,
                        minDay: roomTypeItem.minDay,
                        roomNum: roomTypeItem.roomNum
                    }
                });
            }
        };
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (0, vue_2.createElementVNode)("div", _hoisted_2, [
                    (0, vue_2.createElementVNode)("img", {
                        class: "cardImg cursorPoin",
                        onClick: goOrderReserve,
                        src: _ctx.roomTypeItem.coverImg,
                        alt: ""
                    }, null, 8, _hoisted_3),
                    (0, vue_2.createElementVNode)("div", _hoisted_4, [
                        (0, vue_2.createElementVNode)("div", {
                            class: "cardTitle whNowrap cursorPoin",
                            onClick: goOrderReserve
                        }, [
                            (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.roomTypeItem.roomTypeName) + " ", 1),
                            (0, vue_2.createElementVNode)("span", _hoisted_5, [
                                (0, vue_2.createTextVNode)("（规格： "),
                                (_ctx.roomTypeItem.breakfastFlag == 0)
                                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("span", _hoisted_6, "无"))
                                    : ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("span", _hoisted_7, "有")),
                                (0, vue_2.createTextVNode)("早餐, "),
                                (_ctx.roomTypeItem.busFlag == 0)
                                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("span", _hoisted_8, "不"))
                                    : (0, vue_2.createCommentVNode)("", true),
                                (0, vue_2.createTextVNode)("包含大巴 "),
                                (_ctx.roomTypeItem.minDay)
                                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("span", _hoisted_9, "," + (0, vue_2.toDisplayString)(_ctx.roomTypeItem.minDay) + "天起订", 1))
                                    : (0, vue_2.createCommentVNode)("", true),
                                (0, vue_2.createTextVNode)("）")
                            ])
                        ]),
                        (0, vue_2.createElementVNode)("div", {
                            class: "cardIntroduce whNowrap2",
                            style: { "width": "38.38rem" },
                            innerHTML: _ctx.roomTypeItem.introduce
                        }, null, 8, _hoisted_10),
                        (0, vue_2.createElementVNode)("div", _hoisted_11, [
                            (0, vue_2.createTextVNode)(" ¥" + (0, vue_2.toDisplayString)(_ctx.roomTypeItem.price) + " ", 1),
                            (_ctx.roomTypeItem.remainRoomNum == 0 || _ctx.roomTypeItem.fullHouseFlag == 1)
                                ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("span", _hoisted_12, "无空房"))
                                : (_ctx.roomTypeItem.remainRoomNum <= 10)
                                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("span", _hoisted_13, "（房间余" + (0, vue_2.toDisplayString)(_ctx.roomTypeItem.remainRoomNum) + "）", 1))
                                    : ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("span", _hoisted_14, "（房间余" + (0, vue_2.toDisplayString)(_ctx.roomTypeItem.remainRoomNum) + "）", 1))
                        ])
                    ])
                ]),
                (_ctx.roomTypeItem.remainRoomNum != 0 && _ctx.roomTypeItem.fullHouseFlag != 1)
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", {
                        key: 0,
                        class: "cardLook cursorPoin",
                        onClick: goOrderReserve
                    }, "预定房间"))
                    : (0, vue_2.createCommentVNode)("", true)
            ]));
        };
    }
});
