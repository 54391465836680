import request from '@/utils/request.js'

// 房型列表
export function roomType(data) {
  return request({
    url: '/front/roomType',
    method: 'get',
    params:data
  })
}

// 酒店列表
export function frontHotel(data) {
  return request({
    url: '/front/hotel',
    method: 'get',
    params:data
  })
}

// 酒店详情
export function roomTypeHotel(data) {
  return request({
    url: '/front/hotel/roomType',
    method: 'get',
    params:data
  })
}

// 酒店支付类型
export function paymentMethod(data) {
  return request({
    url: '/front/hotel/paymentMethod',
    method: 'get',
    params:data
  })
}

// 酒店订单
export function hotelOrder(data) {
  return request({
    url: '/front/hotel/order',
    method: 'get',
    params:data
  })
}

// 酒店图片
export function hotelImg(data) {
  return request({
    url: '/front/hotel/img',
    method: 'get',
    params:data
  })
}

// 创建订单
export function hotelOrderAdd(data) {
  return request({
    url: '/front/hotel/order',
    method: 'POST',
    data
  })
}

// 创建订单
export function orderAli(data) {
  return request({
    url: '/front/hotel/order/ali',
    method: 'put',
    data
  })
}

// 创建订单
export function roomTypePrice(data) {
  return request({
    url: '/front/hotel/roomTypePrice',
    method: 'get',
    params:data
  })
}


export function hotelorderPut(data) {
  return request({
    url: '/front/hotel/order',
    method: 'put',
    data
  })
}

export function orderpay(data) {
  return request({
    url: '/front/hotel/order/pay',
    method: 'put',
    data
  })
}
// 支付凭证支付
export function proof(data) {
  return request({
    url: '/front/hotel/order/proof',
    method: 'put',
    data
  })
}
// 支付订单
export function vxpay(data) {
  return request({
    url: '/front/hotel/order/wx',
    method: 'put',
    data
  })
}
// 查询订单
export function orderGet(id) {
  return request({
    url: '/front/hotel/order/'+id,
    method: 'get',
  })
}

// 获取最近的可预定日期
export function earliestGet() {
  return request({
    url: '/front/hotel/earliest',
    method: 'get',
  })
}
